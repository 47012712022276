import { Button, CircularProgress } from '@material-ui/core'
import React, { ChangeEvent, ReactNode } from 'react'
import { makeStyles } from '@material-ui/core/styles'
interface Props {
  size: string
  loading: boolean
  children: ReactNode
  onClick?: Function
}

const useStyles = makeStyles(theme => ({
  margin: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  select: {
    minWidth: 140,
  },
  selectEmpty: {},
  relative: {
    position: 'relative',
  },
  alignCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonProgressText: {
    color: theme.palette.primary.main,
  },
  buttonProgress: {
    color: '#fff',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

export const ButtonWrapper = ({
  children,
  size,
  onClick,
  loading,
}: Props): JSX.Element => {
  const hanldeOnClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ): void => {
    event.preventDefault()
    if (onClick) {
      onClick()
    }
  }

  const classes = useStyles()

  return (
    <>
      <Button
        href={'#'}
        variant="contained"
        color="primary"
        size={size}
        className={loading ? classes.buttonProgressText : ''}
        onClick={hanldeOnClick}
      >
        {children}
      </Button>
      {loading && (
        <CircularProgress className={classes.buttonProgress} size={24} />
      )}
    </>
  )
}
