import axios from 'axios'

const timeout_def = 25000

export type ResponseOk = {
  result: {
    code: string
    message: string
  }
}

export type ResponseError = {
  error: {
    code: string
    message: string
  }
}

export default class ApiDefinition {
  axiosInstance

  constructor() {
    this.axiosInstance = axios.create({
      timeout: timeout_def,
      baseURL: 'https://api.blink-cart.com',
    }) // 'http://localhost/' //
  }

  testConnection = async (data: {
    url: string
    email: string
    software: string
  }): Promise<ResponseOk | ResponseError> => {
    return await this.call<ResponseOk | ResponseError>({
      url: '?e=setup&a=testConnection',
      data: data,
    })
  }

  testApiConnection = async (data: {
    url: string
    token: string
  }): Promise<ResponseOk | ResponseError> => {
    return await this.call<ResponseOk | ResponseError>({
      url: '?e=setup&a=testApiConnection',
      data: data,
    })
  }

  getShippingMethods = async (url: string, country: string): Promise<any> => {
    return await this.call<any>({
      url: '?e=setup&a=getShippingMethods',
      data: { url, country },
    })
  }

  confirmEmail = async (data: {
    email: string
    code: string
  }): Promise<any> => {
    return await this.call<any>({
      url: '?e=setup&a=confirmEmail',
      data: data,
    })
  }

  getShopCreateLogs = async (url: string): Promise<any> => {
    return await this.call<any>({
      url: '?e=setup&a=getShopCreateLogs',
      data: { url },
    })
  }

  createProject = async (
    url: string,
    token: string,
    token_secret: string,
  ): Promise<any> => {
    return await this.call<any>({
      url: '?e=setup&a=createProject',
      data: { url, token, token_secret },
      timeout: 1,
    })
  }

  call = async <T>({
    url,
    method = 'POST',
    data = {},
    timeout = timeout_def,
  }: {
    url: string
    method?: string
    data: any
    timeout?: number
  }): Promise<T | ResponseError> => {
    let results
    console.log(timeout | timeout_def)
    const config = {
      timeout: timeout | timeout_def,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': 'http://localhost:8000',
      },
    }

    // if (method == 'POST') {
    //   results = await this.axiosInstance.post(url, data, config);
    // } else {
    //   results = await this.axiosInstance.get(url, config);
    // }
    // return results.data;

    try {
      if (method == 'POST') {
        results = await this.axiosInstance.post(url, data, config)
      } else {
        results = await this.axiosInstance.get(url, config)
      }
      return results.data
    } catch (e) {
      return {
        error: {
          code: 'CONNECTION_ERROR',
          message: 'Connection error, please try again letter.',
        },
      }
    }
  }
}

const Api = new ApiDefinition()

export { Api }
