import React, { ChangeEvent } from 'react'
import { InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import { Field } from 'types'

interface Props {
  field: Field
  labelOverride: string | undefined
  onChange: (value: unknown, valid: boolean) => void
}

export const FieldWrapper = ({
  field,
  onChange,
  labelOverride,
}: Props): JSX.Element => {
  const { type, value, options } = field
  let { label } = field

  if (labelOverride) {
    label = labelOverride
  }

  const handleOnChangeSelect = (
    event: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>,
  ) => {
    handleOnChange(event.target.value)
  }

  const handleOnChangeText = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    console.log(event.target.id)
    handleOnChange(event.target.value)
  }

  const handleOnChange = (value: unknown) => {
    onChange(value, true)

    // rules.forEach((rule) => {
    //   if (!rule(value)) {
    //     valid = false;
    //   }
    // });

    // if (!valid) {
    // }
  }
  if (type === 'select') {
    return (
      <>
        <InputLabel>{label}</InputLabel>
        <Select
          variant="filled"
          value={value}
          onChange={handleOnChangeSelect}
          label={label}
        >
          {options.map((el: any) => (
            <MenuItem value={el.value}>{el.name}</MenuItem>
          ))}
        </Select>
      </>
    )
  }
  return (
    <TextField
      variant="filled"
      label={label}
      value={value}
      onChange={handleOnChangeText}
    />
  )
}
